<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{this.company}} PDP Policy Data</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                
                <argon-button color="success" size="sm" class="ms-auto" @click="exportToExcel">Export to Excel</argon-button>
              </div>
            </div>
            <div class="card-body">
              
              <div v-if="!isAgent" class="col-md-12">
                <select id="filter" name="filter" class="form-select-sm" v-model="selectedFilter" @change.prevent="filter()">
                  <option v-for="element in filters" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
                &nbsp;
                <a href=# @click.prevent="getData(selectedYear, selectedMonth, selectedFilter, searchVal)">&nbsp;<i class="fa fa-magnifying-glass text-success text-sm opacity-10"></i></a>
                <br><br>
                <input type="text" id="searchVal" name="searchVal" class="form-control" v-model="searchVal" title="Search" placeholder="Search" ></input>&nbsp;
                &nbsp;&nbsp;
              </div> 

              <div class="col-md-12">
                Year
                <select id="year" name="year" class="form-select" v-model="selectedYear" @change="getData(selectedYear, selectedMonth, selectedFilter, searchVal)" required>
                  <option v-for="element in years" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
                Month
                <select id="months" name="months" class="form-select" v-model="selectedMonth" @change="getData(selectedYear, selectedMonth, selectedFilter, searchVal)" required>
                  <option v-for="element in months" :value="element.id" :key="element.id">
                    {{ element.name }}
                  </option>
                </select>
              </div>
              <br>
              <div class="col-md-12 scrollable-div">
                <div class="content">
                  <ul class="ul">
                    <li>
                      <span class="td2 heading">
                        Writing Agent
                      </span>

                      <span class="td1 heading">
                        Carrier 
                      </span>

                      <span class="td4 heading">
                        Product
                      </span>

                      <span class="td2 heading">
                        Policy Number
                      </span>

                      <span class="td3 heading">
                        Client Name
                      </span>

                      <span class="td2 heading">
                        Submitted Date
                      </span>

                      <span class="td1 heading">
                        Payout Eligible
                      </span>

                      <br>
                    </li>
                  </ul>
              
                  <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                  <li>
                    <span class="td2">                      
                      {{ element.writing_agent }}
                    </span>

                    <span class="td1">
                      {{element.carrier}}
                    </span>

                    <span class="td4">
                      {{element.product}}
                    </span>

                    <span class="td2">
                      {{element.policy_number}}
                    </span>

                    <span class="td3" :title="element.product">
                      {{element.client_name}}
                    </span>

                    <span class="td2">
                      {{element.submitted_date.substring(0,10)}}
                    </span>

                    <span class="td1">
                      {{element.payout_eligible}}
                    </span>

                    <br>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

import "@/assets/css/fontawesome.css";

import * as XLSX from "xlsx";

const company = sessionStorage.getItem("company");
var message = "";
var selectedFilter = 4;

var selectedYear = String(new Date().getFullYear());
var selectedMonth = new Date().getMonth() - 5;

export default {
  name: "Pdp",
  data() {
    return {
      showMenu: false,
      elements: [],
      company,
      filters: [],
      selectedFilter,
      searchVal: '',
      message,
      role: sessionStorage.getItem('role'),
      years: [{'id':2025,'name':'2025'}, {'id':2024,'name':'2024'}, {'id':2023,'name':'2023'}],
      selectedYear,
      months: [{'id':1,'name':'January'}, {'id':2,'name':'February'},{'id':3,'name':'March'}, {'id':4,'name':'April'},
      {'id':5,'name':'May'}, {'id':6,'name':'June'},{'id':7,'name':'July'}, {'id':8,'name':'August'},
      {'id':9,'name':'September'}, {'id':10,'name':'October'},{'id':11,'name':'November'}, {'id':12,'name':'December'}
      ],
      selectedMonth
    }
  },
  computed: {
    now() {
      return new Date(); // or use Date.now() if you prefer timestamps
    },
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    isOlderThan120Days(effectiveDate) {
      const effectiveDateObj = new Date(effectiveDate);
      const differenceInDays = (this.now - effectiveDateObj) / (1000 * 60 * 60 * 24);
      return differenceInDays > 120;
    },
    async getData(year, month, selectedFilter, searchVal) {
      
      //console.log(year, month, selectedFilter, searchVal);

      let response = await fetch("/apis/pdp/" + year + '/' + month + '/' + selectedFilter + '/' + searchVal);
      this.elements = await response.json();
      this.message = this.elements.length + ' Records';
    },
    async getLookups() {        
      let response4 = await fetch("/apis/get_lu/filters");
      this.filters = await response4.json();
    },
    search() {
      this.getData(this.selectedYear, this.selectedMonth, this.selectedFilter, this.searchVal);
    },
    filter() {
      this.searchVal = '';      
    },
    exportToExcel() {
      // Convert JSON data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(this.elements);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "PDP");

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, "pdp_data.xlsx");
    },
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData(selectedYear, selectedMonth, 4, undefined);
    this.getLookups();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>

<style>

.scrollable-div {
  width: 1024px; /* Fixed width */
  overflow-x: scroll; /* Enables horizontal scrolling */
  overflow-y: hidden; /* Hides vertical scrolling */
  white-space: nowrap; /* Prevents content from wrapping */
  border: 1px solid #ddd; /* Optional: For visual clarity */
  padding: 10px; /* Optional: For spacing inside the div */
}

.scrollable-div::-webkit-scrollbar {
  height: 10px; /* Makes scrollbar visible and sets height */
}

.scrollable-div::-webkit-scrollbar-thumb {
  background: #888; /* Style the scrollbar thumb */
  border-radius: 5px; /* Rounded edges */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovered */
}

.content {
  width: 1024px; /* Ensure the content inside is wider than the div */
}

</style>