<template>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            v-bind:darkMode="true"
            isBtn="bg-gradient-success"
          />
        </div>
      </div>
    </div>
    <br><br><br><br>
    <main class="mt-0 main-content">
      <section>
  
      <div class="py-4 container-fluid">
  
      </div>
      </section>
      <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header pb-0">
                <div class="d-flex align-items-center">
                  <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>
                </div>
              </div>
              
              <div class="card-body">
                
                <br><br><br><br><br>

                <div class="card-header pb-0">
                    <div>
                        <!-- Button to load the component -->
                        <button @click="loadChildRoute">Load Component</button>

                        <!-- Div to dynamically load the component -->
                        <div>
                        <component :is="dynamicComponent" />
                        </div>
                    </div>
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>


<script>
export default {
  data() {
    return {
      dynamicComponent: null, // Holds the dynamic component
    };
  },
  methods: {
    loadChildRoute() {
      // Navigate to the /child route
      this.$router.push('/child');
    },
  },
};
</script>