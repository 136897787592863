<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">                
                <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{company}} Agents</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div v-if="!isAgent" class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                
                <argon-button v-if="isFYMAdmin" color="success" size="sm" class="ms-auto" @click.prevent="newelement('Agent')">Create Agent</argon-button>
              </div>
            </div>
            <div class="card-body">

              <div hidden class="col-md-12">
                <form id="search-form" role="form" method="POST" enctype="multipart/form-data" @submit.prevent="search()">                  
                  <a id="search_link" name="search_link" href=# @click.prevent="search()" title="search" hidden><i class="ni ni-zoom-split-in text-info text-sm opacity-10"></i></a>
                  <input id="search" name="search" type="text" class="form-control form-control-default invalid" title="Search" placeholder="Search" @keyup.prevent="search()">
                </form>
              </div>

              <ul class="ul">
                  <li>
                    <span class="td1"> 
                    </span>

                    <span class="td1 heading">
                      Agent NPN
                    </span>
                       
                    <span class="td2 heading">
                      Firstname
                    </span>

                    <span class="td2 heading">
                      Lastname
                    </span>

                    <span v-if="isFYMAdmin" class="td1 heading">
                      Agency
                    </span>

                    <span class="td3 heading">
                      Status                      
                    </span>

                    <span class="td1 heading">
                      Agent rate
                    </span>

                    <span class="td1 heading">
                      Systems
                    </span>

                  </li>

                  <li v-if="!isAgent">
                    <span class="td1"> 
                    </span>

                    <span class="td1 heading">                    
                    </span>
                    
                    <span class="td2 heading">
                    </span>
                    
                    <span class="td2 heading">
                    </span>

                    <span hidden class="td1 heading">
                      <select class="form-select" v-model="selectedAgency" @change="search2(selectedAgency, selectedStatus)">
                        <option value="0" selected>All</option>
                        <option value="1">FYM</option>
                        <option value="2">Tarkenton</option>
                        <option value="3">Oasis</option>
                        <option value="4">Collins</option>
                        <option value="5">Medcora</option>
                        <option value="6">Wise Choice</option>
                        <option value="7">Aspire</option>
                        <option value="8">HIP</option>
                        <option value="9">Csipkes</option>
                        <option value="10">PBG</option>
                      </select>
                    </span>

                    <span hidden class="td3 heading">
                      <select class="form-select" v-model="selectedStatus" @change="search2(selectedAgency, selectedStatus)">
                        <option value="0" selected>All</option>
                        <option value="1">Active</option>
                        <option value="2">Contracting</option>
                        <option value="3">Inactive</option>
                        <option value="4">Questioning</option>
                        <option value="5">Terminated</option>
                        <option value="6">Terminated with cause</option>                   
                      </select>
                    </span>

                    <span class="td1 heading">
                    </span>

                    <span class="td1 heading">
                    </span>

                  </li>

              </ul>

              <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                  <li>
                    <span class="td1">
                      <i v-if="element.sysrole===0" class="fa fa-user-md text-dark text-sm opacity-10" title="FYM Admin"></i>&nbsp;
                      <i v-else-if="element.sysrole===1" class="fa fa-users text-primary text-sm opacity-10" title="Agency Admin"></i>&nbsp;
                      <i v-else-if="element.sysrole===2" class="fa fa-users text-info text-sm opacity-10" title="Agent Manager"></i>&nbsp;
                      <i v-else class="fa fa-user text-success text-sm opacity-10" title="Agent"></i>&nbsp;
                      <router-link :to="{ name: 'Agent', params: {id: element.id}}" class="font-weight-bold">
                        <i class="fa fa-ellipsis-v text-xs"></i>
                        &nbsp;<a
                        href="#"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"                   
                        > Edit</a>
                      </router-link>
                    </span>
                        
                    <span class="td1" @click="copy_npn(element.agent_npn)" title="Copy NPN to clipboard">
                      {{element.agent_npn}}
                    </span>
                       
                    <span class="td2" :title="element.phone">
                      {{element.firstname}}
                    </span>

                    <span class="td2" :title="element.email">
                      {{element.lastname}}
                    </span>

                    <span v-if="isFYMAdmin" class="td1" :title="element.agent_type">
                      {{element.agency}}
                    </span>

                    <span 
                      class="td3"
                      :class="{
                        'fym-good': element.agent_status === 'Active',
                        'fym-almostgood': element.agent_status === 'Contracting',
                        'fym-fail': element.agent_status === 'Terminated' || element.agent_status === 'Terminated with cause',
                        'fym-alert': element.agent_status !== 'Active' && element.agent_status !== 'Terminated' && element.agent_status !== 'Terminated with cause'
                      }"
                      :title="'First sale date: ' + formattedDate(new Date(element.first_sale_dt)) + ', Last sale date: ' + formattedDate(new Date(element.last_sale_dt))"
                      >
                      {{ element.agent_status }}
                    </span>

                    <span class="td1">
                      {{element.agent_rate}}
                    </span>

                    <span class="td1">
                      <i v-if="element.enrollhere" class="fa fa-phone fym-good text-sm opacity-10" title="enrollhere"></i>
                      <i v-else class="fa fa-phone fym-fail text-sm opacity-10" title="not on enrollhere"></i>
                      &nbsp;
                      <i v-if="element.sunfire" class="fa fa-sun fym-good text-sm opacity-10" title="sunfire"></i>
                      <i v-else class="fa fa-sun fym-fail text-sm opacity-10" title="not on sunfire"></i>                      
                      &nbsp;
                      <i v-if="element.bill_id" :id="element.id" class="fa fa-credit-card fym-good text-sm opacity-10" title="bill id"></i>
                      <i v-else :id="element.id" class="fa fa-credit-card fym-fail text-sm opacity-10" title="no bill id" @click.prevent="getBillId(`${element.id}`, `${element.firstname}`, `${element.lastname}`)"></i>
                      &nbsp;
                      <span v-if="isFYMAdmin">
                        <i v-if="element.signed_contract" class="fa fa-file fym-good text-sm opacity-10" title="signed contract" @click.prevent="getContract(`${element.agent_npn}`, element)"></i>
                        <i v-else class="fa fa-file fym-fail text-sm opacity-10" title="no signed contract"></i>
                      </span>
                    </span>
                    
                    <br>
                  </li>
              </ul>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

const company = sessionStorage.getItem("company");

var message = "";
var filter = "";

export default {
  name: "Agents",
  data() {
    return {
      showMenu: false,
      elements: [],
      company,
      message,
      filter,
      role: sessionStorage.getItem('role'),
      sysgroup: sessionStorage.getItem('sysgroup'),
      selectedAgency: '0',
      selectedStatus: '0'
    }
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    formattedDate(date) {
      if (!(date instanceof Date) || isNaN(date)) {
        date = new Date('1900-01-01T06:00:00Z')
      }
      return date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0');
    },
    serializeForm(){
      
      let myForm = document.getElementById('search-form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async getData() {
      let response = await fetch('/apis/MyWorkflow');
      this.elements = await response.json();
      
      this.message = this.elements.length + ' Agents in the ' + this.sysgroup + ' queue ';
      
    },
    async getBillId(agent_id, agent_fn, agent_ln) {
      
      let response = await fetch("/apis/get_bill_id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          agent_id,
          agent_fn,
          agent_ln,
          element, // Include the `element` object here
        }),
      });
      
      //let response = await fetch("/apis/get_bill_id/" + agent_id + '/' + agent_fn + ' ' + agent_ln);
      let data = await response.json();
      if (data.result == 'bill_id_updated') {
        this.message = 'bill.com id updated';
        
        const element = this.elements.find(el => el.id === agent_id); // Assuming 'elements' is your array of objects
        if (element) {
          element.bill_id = data.message; // Update the bill_id with the response from the server
          this.message = 'Bill.com ID successfully updated';
        } else {
          console.error('Element not found');
        }


      }
    },
    async newelement(type) {
      router.push({ name: type, params: {id: 0}});
    },
    async search() {
      var filter = document.getElementById('search').value;
      if (filter == '') {
        let response = await fetch('/apis/Agents');
        this.elements = await response.json();
        this.message = this.elements.length + ' Agents';
      } 
      else {   
        if (filter.length > 1) {  
          let response = await fetch('/apis/agent_search/' + filter);
          this.elements = await response.json();    
          this.message = this.elements.length + ' Agents';      
        }
      }
    },    
    async search2(filter1, filter2) {      
      if (filter1 == '0' && filter2 == '0') {
        let response = await fetch('/apis/Agents');
        this.elements = await response.json();
        this.message = this.elements.length + ' Agents';
      } 
      else if(filter1 !== '0' && filter2 !== '0') {   
        let response = await fetch('/apis/agent_search_by_agency_and_status/' + filter1 + '/' + filter2);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 !== '0' && filter2 == '0') {   
        let response = await fetch('/apis/agent_search_by_agency/' + filter1);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else {   
        let response = await fetch('/apis/agent_search_by_status/' + filter2);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
    },
    async copy_npn(cmd) {
      navigator.clipboard.writeText(cmd);
      console.log('copied');
    },
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>